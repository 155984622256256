import FF from "../../components/forms/FormField.module.css";
import TF from "../../components/forms/TextField.module.css";

import { useControlledState } from "@react-stately/utils";
import { Navigate } from "@tanstack/react-router";
import type { LoadBalancerCreateBody } from "@warrenio/api-spec/spec.oats.gen";
import { findById } from "@warrenio/utils/collections/findById";
import type { Subset } from "@warrenio/utils/types/Subset";
import { useAtomValue } from "jotai/react";
import { atom } from "jotai/vanilla";
import { Suspense, type ReactNode } from "react";
import { Input } from "react-aria-components";
import { Controller, useForm, useFormContext, type SubmitHandler } from "react-hook-form";
import { isArray } from "remeda";
import { HeroBlock } from "../../components/HeroBlock.tsx";
import { Separator } from "../../components/Separator.tsx";
import { CreateAndAssignIpField, IpAddressAllPrices } from "../../components/forms/CreateAndAssignIpField.tsx";
import { CreateFormAction } from "../../components/forms/CreateFormAction.tsx";
import { FormField } from "../../components/forms/FormField.tsx";
import { IconRadio, type IconRadioContentProps } from "../../components/forms/IconRadio.tsx";
import { MonthlyCostElement, MonthlyCostField } from "../../components/forms/MonthlyCostField.tsx";
import { WHookForm } from "../../components/forms/WHookForm.tsx";
import { WRadioGroup } from "../../components/forms/WRadioGroup.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { useAriaField } from "../../components/forms/ariaFieldRegister.ts";
import { requiredMessage } from "../../components/forms/requiredMessage.ts";
import { LoadingSuspense } from "../../components/loading/Loading.tsx";
import { useConfig } from "../../config.ts";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { resourceTypesEnabledAtom, type ResourceType } from "../api/resourceTypes.tsx";
import { useStandardMutation, useStandardSuspenseQuery } from "../api/useStandardMutation.ts";
import { BillingAccountField } from "../billing/forms/BillingAccountField.tsx";
import { LocationField, type LocationInputs } from "../location/LocationField.tsx";
import { useLocationsForType } from "../location/query.ts";
import { PricingModal } from "../pricing/PricingModal.tsx";
import { pricesAtom } from "../pricing/query.ts";
import { FREE_PRICE, getIpAddressPrices, getLoadBalancerPrice } from "../pricing/resourcePricing.ts";
import { AssignToSelect } from "./AssignToSelect.tsx";
import { getDefaultVpc, VpcNetworkSelect } from "./VpcNetworkSelect.tsx";
import { createFullIpAddressMutation } from "./ipAddress/apiOperations.ts";
import { getAddressFromResource, UNASSIGNED_RESOURCE, type IpAssignableResource } from "./ipAddress/resourceId.ts";
import { ipAddressLink, loadBalancerLink, vpcLink } from "./links.ts";
import { ForwardingRulesList } from "./loadbalancer/ForwardingRulesList.tsx";
import { TargetServersList } from "./loadbalancer/TargetServersList.tsx";
import { createLoadBalancerMutation } from "./loadbalancer/apiOperations.ts";
import { createVpcNetworkMutation, getSingleQuery } from "./vpc/apiOperations.ts";

interface ItemType extends IconRadioContentProps {
    id: NetworkFormType;
    description: ReactNode;
}

const typesAtom = atom((get) => {
    const items: ItemType[] = [
        {
            id: "ip_address",
            title: "Floating IP",
            icon: "jp-network-2-icon",
            description:
                "Allocating a new IPv4 address allows you to assign it to one of your existing resources or reserve it for later use.",
        },
        {
            id: "vpc",
            title: "VPC Network",
            icon: "jp-network-icon",
            description:
                "A virtual private cloud (VPC) is a private network interface for collections of your resources. VPC network is available at no additional cost.",
        },
        {
            id: "load_balancer",
            title: "Load Balancer",
            icon: "jp-load-balancer-icon",
            description:
                "High availability load balancer distributes traffic between virtual machines within the same data center location.",
        },
    ];
    const enabled = get(resourceTypesEnabledAtom);
    return items.filter((item) => enabled[item.id]);
});

export interface CommonInputs extends LocationInputs {
    name: string;
    billing_account_id?: number;
}

export type NetworkFormType = Subset<"ip_address" | "vpc" | "load_balancer", ResourceType>;

type Inputs = AddressCreateInputs | VpcCreateInputs | BalancerCreateInputs;

export interface NetworkCreateProps {
    typeId?: NetworkFormType;
    defaultTypeId?: NetworkFormType;
    onChangeType?: (type: NetworkFormType) => void;

    inputs?: Partial<Inputs>;
}

function NetworkForm({ inputs, typeId: controlledTypeId, defaultTypeId, onChangeType }: NetworkCreateProps) {
    const types = useAtomValue(typesAtom);
    const [typeId, setType] = useControlledState(controlledTypeId, defaultTypeId ?? types[0].id, onChangeType);

    const type = findById(types, typeId);

    return (
        <>
            <WRadioGroup label="Type" isRequired value={typeId} onChange={setType} description={type.description}>
                {types.map((item) => (
                    <IconRadio key={item.id} {...item} />
                ))}
            </WRadioGroup>
            <Separator />
            <LoadingSuspense>
                {typeId === "vpc" ? (
                    <VpcCreateForm inputs={inputs} />
                ) : typeId === "load_balancer" ? (
                    <BalancerCreateForm inputs={inputs} />
                ) : (
                    <AddressCreateForm inputs={inputs} />
                )}
            </LoadingSuspense>
        </>
    );
}

interface AddressCreateInputs extends CommonInputs {
    assignedResource: IpAssignableResource | UNASSIGNED_RESOURCE;
}

export function AddressCreateForm({ inputs }: { inputs?: Partial<AddressCreateInputs> }) {
    const ipMutation = useStandardMutation(createFullIpAddressMutation);
    const locations = useLocationsForType("ip_address");
    const form = useForm<AddressCreateInputs>({
        disabled: ipMutation.isPending,
        defaultValues: {
            location: locations.defaultLocation,
            assignedResource: UNASSIGNED_RESOURCE,
            ...inputs,
        },
    });
    const { watch, control } = form;

    const onSubmit: SubmitHandler<AddressCreateInputs> = async ({ location, assignedResource, ...body }) => {
        await ipMutation.mutateAsync({
            location,
            ...(assignedResource !== UNASSIGNED_RESOURCE && { private_ip: getAddressFromResource(assignedResource) }),
            body,
        });
    };

    if (ipMutation.isSuccess) {
        return <Navigate {...ipAddressLink(ipMutation.data.result)} />;
    }

    return (
        <WHookForm form={form} onSubmit={onSubmit}>
            {locations.showLocations && (
                <>
                    <LocationField locations={locations} />
                    <Separator />
                </>
            )}

            <FormField
                label="Assign to"
                description="Choose a resource to assign the IP to or leave it unassigned. You can always assign the IP to a suitable resource later"
            >
                <Controller
                    control={control}
                    name="assignedResource"
                    render={({ field }) => (
                        <AssignToSelect
                            location={watch("location")}
                            isDisabled={field.disabled}
                            defaultValue={field.value ?? UNASSIGNED_RESOURCE}
                            onChange={field.onChange}
                        />
                    )}
                />
            </FormField>

            <NameField isRequired={false} />

            <Separator />

            <Suspense>
                <AddressMonthlyCostField location={watch("location")} />
            </Suspense>

            <BillingAccountField resourceType="ip_address" isRequired />

            <Separator />

            <CreateFormAction resourceType="ip_address" />
        </WHookForm>
    );
}

function NetworkCostDescription({ location }: { location: string }) {
    return (
        <>
            The price is approximate, actual cost of your resource will be calculated based on your actual hourly usage.{" "}
            <PricingModal defaultLocation={location}>
                Please see how the cost of your resource is calculated.
            </PricingModal>
        </>
    );
}

export function AddressMonthlyCostField({ location }: { location: string }) {
    const priceList = useSuspenseQueryAtom(pricesAtom);
    const prices = getIpAddressPrices(priceList, location);

    return (
        <MonthlyCostField description={<NetworkCostDescription location={location} />}>
            <IpAddressAllPrices prices={prices} />
        </MonthlyCostField>
    );
}

export interface BalancerCreateInputs extends LoadBalancerCreateBody, Pick<CommonInputs, "location"> {}

export function BalancerCreateForm({ inputs }: { inputs?: Partial<BalancerCreateInputs> }) {
    const { privateNetworksEnabled } = useConfig();
    const createMutation = useStandardMutation(createLoadBalancerMutation);
    const locations = useLocationsForType("load_balancer");
    // XXX: Might be a better way to get the default vpc for vpc select
    const { data } = useStandardSuspenseQuery(getSingleQuery, { location: locations.defaultLocation });
    const networkUuid = getDefaultVpc([...data.values()])?.uuid ?? "";
    const form = useForm<BalancerCreateInputs>({
        disabled: createMutation.isPending,
        defaultValues: {
            reserve_public_ip: true,
            location: locations.defaultLocation,
            network_uuid: networkUuid,
            // NB: Must set default values for all required form fields
            targets: [],
            rules: [{ source_port: 80, target_port: 80 }],
            ...inputs,
        },
    });
    const { watch, control } = form;

    const onSubmit: SubmitHandler<BalancerCreateInputs> = async ({ location, ...body }) => {
        await createMutation.mutateAsync({ location, body });
    };

    if (createMutation.isSuccess) {
        return <Navigate {...loadBalancerLink(createMutation.data)} />;
    }

    return (
        <WHookForm form={form} onSubmit={onSubmit}>
            {locations.showLocations && (
                <>
                    <LocationField locations={locations} />
                    <Separator />
                </>
            )}

            <CreateAndAssignIpField<BalancerCreateInputs>
                name="reserve_public_ip"
                control={control}
                location={watch("location")}
            />

            {privateNetworksEnabled && (
                <FormField
                    isRequired
                    label="VPC network"
                    description="Choose a VPC network for your resource from already existing one or create a new private network."
                >
                    <Controller
                        control={control}
                        name="network_uuid"
                        render={({ field }) => (
                            <VpcNetworkSelect
                                location={watch("location")}
                                isDisabled={field.disabled}
                                defaultValueKey={field.value}
                                onChange={(_vpc, key) => field.onChange(key)}
                            />
                        )}
                    />
                </FormField>
            )}

            <Separator />

            <Controller
                control={control}
                name="targets"
                rules={{ validate: (value) => value.length > 0 || "Add at least one target server." }}
                render={({ field, fieldState: { error } }) => (
                    <FormField wide isRequired label="Target servers" errorMessage={error?.message}>
                        <TargetServersList
                            location={watch("location")}
                            networkUuid={watch("network_uuid")}
                            defaultTargets={field.value}
                            onInputChange={field.onChange}
                        />
                    </FormField>
                )}
            />

            <Separator />

            <Controller
                control={control}
                name="rules"
                rules={{ validate: (value) => value.length > 0 || "Add at least one forwarding rule." }}
                render={({ fieldState }) => {
                    const { error } = fieldState;
                    // XXX: For some reason errors become arrays here
                    const realError = isArray(error) ? error.root : error;
                    return (
                        <FormField wide isRequired label="Forwarding rules" errorMessage={realError?.message}>
                            <ForwardingRulesList />
                        </FormField>
                    );
                }}
            />

            <Separator />

            <DisplayNameField isRequired />

            <Suspense>
                <BalancerMonthlyCostField location={watch("location")} reserve_public_ip={watch("reserve_public_ip")} />
            </Suspense>

            <BillingAccountField resourceType="load_balancer" isRequired />

            <Separator />

            <CreateFormAction resourceType="load_balancer" />
        </WHookForm>
    );
}

export function BalancerMonthlyCostField({
    location,
    reserve_public_ip,
}: {
    location: string;
    reserve_public_ip: boolean;
}) {
    const prices = useSuspenseQueryAtom(pricesAtom);
    const balancerCost = getLoadBalancerPrice(prices, location, reserve_public_ip);

    return (
        <MonthlyCostField description={<NetworkCostDescription location={location} />}>
            <MonthlyCostElement price={balancerCost} />
        </MonthlyCostField>
    );
}

interface VpcCreateInputs extends CommonInputs {}

export function VpcCreateForm({ inputs }: { inputs?: Partial<VpcCreateInputs> }) {
    const vpcMutation = useStandardMutation(createVpcNetworkMutation);
    const locations = useLocationsForType("vpc");
    const form = useForm<VpcCreateInputs>({
        disabled: vpcMutation.isPending,
        defaultValues: {
            location: locations.defaultLocation,
            ...inputs,
        },
    });

    const onSubmit: SubmitHandler<VpcCreateInputs> = async ({ location, ...body }) => {
        await vpcMutation.mutateAsync({ location, body });
    };

    if (vpcMutation.isSuccess) {
        return <Navigate {...vpcLink(vpcMutation.data)} />;
    }

    return (
        <WHookForm form={form} onSubmit={onSubmit}>
            {locations.showLocations && (
                <>
                    <LocationField locations={locations} />
                    <Separator />
                </>
            )}

            <NameField isRequired />

            <Separator />

            <MonthlyCostField description="VPC network is available at no additional cost.">
                <MonthlyCostElement price={FREE_PRICE} />
            </MonthlyCostField>

            <Separator />

            <CreateFormAction resourceType="vpc" />
        </WHookForm>
    );
}

function NameField({ isRequired }: { isRequired: boolean }) {
    const form = useFormContext<CommonInputs>();
    const { ref, props } = useAriaField("name", form, { required: isRequired ? requiredMessage : false });
    return (
        <WTextField {...props} label="Resource name">
            <Input ref={ref} className={cn(FF.FormFieldInput, TF.Input)} />
        </WTextField>
    );
}

function DisplayNameField({ isRequired }: { isRequired: boolean }) {
    const form = useFormContext<BalancerCreateInputs>();
    const { ref, props } = useAriaField("display_name", form, { required: isRequired ? requiredMessage : false });
    return (
        <WTextField {...props} label="Resource name">
            <Input ref={ref} className={cn(FF.FormFieldInput, TF.Input)} />
        </WTextField>
    );
}

export function NetworkCreate(props: NetworkCreateProps) {
    return (
        <>
            <CreateHero />
            <NetworkForm {...props} />
        </>
    );
}

function CreateHero() {
    return (
        <HeroBlock title="Create New Network Resource" icon="jp-network-icon">
            <p className="text-muted">Start managing your networks by creating a new network resource.</p>
        </HeroBlock>
    );
}
